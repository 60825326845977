import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';

import getDropDownItem from 'utils/dropDownControl';
import { ISafetyInstructionsList } from './models';

import './SafetyInstructionsList.scss';

const SafetyInstructionsList: FC<ISafetyInstructionsList> = ({ data, id }) => (
  <ul id={id} className="dt-safety-instructions__list">
    {data.map(({ properties: { iconName, description } }) => (
      <li key={`safety-instructions-${iconName}`}>
        <IconCustom icon={getDropDownItem(iconName)} />
        <p className="dt-safety-instructions__text">{description}</p>
      </li>
    ))}
  </ul>
);

export default SafetyInstructionsList;
