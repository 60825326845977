import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Collapse from 'react-bootstrap/Collapse';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import HTag from 'components/common/HTag';
import SafetyInstructionsList from './SafetyInstructionsList';

import { ISafetyInstructions } from './models';

import './SafetyInstructions.scss';

const SafetyInstructions: FC<ISafetyInstructions> = ({
  instructions,
  titleBold,
  titleRegular,
  showMoreText,
  additionalText,
}) => {
  const [isToggle, setToggle] = useState(false);
  const instructionsMore = instructions?.slice(4, instructions.length);
  const instructionsLess = instructions?.slice(0, 4);
  const isBtnShown = instructions && instructions?.length > 4;

  const btnClasses = classNames('dt-show-more', { 'dt-show-more--active': isToggle });

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  return (
    <div className="dt-safety-instructions">
      <HTag size={3} regularText={titleRegular} boldText={titleBold} />

      {instructionsLess ? <SafetyInstructionsList data={instructionsLess} /> : null}

      {instructionsMore ? (
        <Collapse in={isToggle}>
          <div>
            <SafetyInstructionsList id="dt-safety-instructions" data={instructionsMore} />
          </div>
        </Collapse>
      ) : null}

      {isBtnShown ? (
        <button
          className={btnClasses}
          type="button"
          onClick={handleToggle}
          aria-controls="dt-safety-instructions"
          aria-expanded={isToggle}
        >
          <IconCustom icon="plus_icon" />
          {showMoreText}
        </button>
      ) : null}
      {additionalText ? (
        <DangerouslySetInnerHtml
          className="dt-safety-instructions__additional-text"
          html={additionalText}
        />
      ) : null}
    </div>
  );
};

export default SafetyInstructions;
